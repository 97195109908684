import React from 'react';
import Chatbot from './Chatbot'; // Adjust the path if necessary
import heroImage from '../assets/porsche_hero_turbo.png';// Ensure this path is correct
import './homepage.css'; // Ensure this path is correct

function HomePage() {
    return (
        <div className="home-container">
            <div className="hero-image-container">
                <img src={heroImage} alt="Porsche 911 Turbo" className="hero-image" />
            </div>
            <div className="overlay-container">
                <Chatbot />
            </div>
            <div className="content">
                <h1>Welcome to WhatThePorsche</h1>
                <p>Let's Talk Porsche.</p>
            </div>
        </div>
    );
}

export default HomePage;

