import React, { useState } from 'react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { MainContainer, ChatContainer, MessageList, Message, MessageInput, TypingIndicator } from '@chatscope/chat-ui-kit-react';
import './Chatbot.css'; // Ensure this path is correct

const OPENAI_API_KEY = "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"; // Replace with your actual API key

function Chatbot() {
    const [chatMessages, setChatMessages] = useState([
        {
            message: "Hello, I'm a Porsche expert! Let's talk Porsche.",
            sender: "ChatGPT",
        },
    ]);
    const [isChatbotTyping, setIsChatbotTyping] = useState(false);

    const handleUserMessage = async (userMessage) => {
        const newUserMessage = {
            message: userMessage,
            sender: "user",
            direction: "outgoing",
        };

        setChatMessages([...chatMessages, newUserMessage]);

        setIsChatbotTyping(true);

        try {
            const response = await fetch("https://api.openai.com/v1/chat/completions", {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${OPENAI_API_KEY}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    model: "gpt-3.5-turbo",
                    messages: [
                        { role: "system", content: "You are a knowledgeable Porsche expert." },
                        ...chatMessages, newUserMessage
                    ].map(msg => ({
                        role: msg.sender === "ChatGPT" ? "assistant" : "user",
                        content: msg.message
                    }))
                }),
            });

            const data = await response.json();

            if (data.choices && data.choices[0] && data.choices[0].message) {
                const chatGPTResponse = {
                    message: data.choices[0].message.content,
                    sender: "ChatGPT",
                };
                setChatMessages(prevMessages => [...prevMessages, chatGPTResponse]);
            }
        } catch (error) {
            console.error("Error in processing message:", error);
        } finally {
            setIsChatbotTyping(false);
        }
    };

    return (
        <div style={{ position: "relative", height: "500px", width: "350px" }}>
            <MainContainer>
                <ChatContainer>
                    <MessageList typingIndicator={isChatbotTyping ? <TypingIndicator content="ChatGPT is thinking" /> : null}>
                        {chatMessages.map((message, i) => (
                            <Message
                                key={i}
                                model={{
                                    message: message.message,
                                    direction: message.sender === "ChatGPT" ? "incoming" : "outgoing",
                                }}
                            />
                        ))}
                    </MessageList>
                    <MessageInput placeholder="Type your message here" onSend={handleUserMessage} />
                </ChatContainer>
            </MainContainer>
        </div>
    );
}

export default Chatbot;



